import React, { FC, memo, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { graphql } from 'gatsby';
import { subscriptionStrings } from 'templates/Coupons/constants';

import runOnUserInteraction from 'utils/runOnUserInteraction';

import Form from '../Form';

import './SubscribeForm.scss';

const SubscribeForm: FC<PageContent.SubscribeFormType> = ({ caption, signHandler }) => {
  const [shouldLoadGoogleReCaptchaProvider, setShouldLoadGoogleReCaptchaProvider] = useState(false);

  const { validationMessage, emailExistsValidationMessage, customButton, inputs } =
    subscriptionStrings;

  const ConsentForm = memo(() => (
    <Form
      {...{
        validationMessage,
        emailExistsValidationMessage,
        customButton,
        inputList: [
          {
            ...inputs.name,
          },
          {
            ...inputs.email,
          },
          {
            ...inputs.dropdown,
          },
          {
            ...inputs.animals,
          },
          {
            ...inputs.receiving,
          },
          {
            ...inputs.terms,
          },
        ],
        agreements: [
          {
            brand: 'Resolve',
            market: 'CA',
            agreements: [
              {
                BusinessId: process.env.GATSBY_CDP_BUSINESS_ID!,
                RevisionId: process.env.GATSBY_CDP_REVISION_ID!,
                ConsentAcceptedInd: true,
                ConsentDesc: 'Resolve',
                MandatoryInd: false,
              },
            ],
          },
        ],
        handleSuccessfulSubmit: signHandler,
        formKeys: {
          programCode: process.env.GATSBY_CDP_PROGRAM_CODE,
          brandOrgCode: process.env.GATSBY_CDP_BRAND_ORG_CODE,
          accountSource: process.env.GATSBY_CDP_ACCOUNT_SOURCE,
        } as FormContent.FormKeysType,
      }}
    />
  ));

  useEffect(() => {
    runOnUserInteraction(() => setShouldLoadGoogleReCaptchaProvider(true));
  }, []);

  return (
    <section className="subscribe-form form-section">
      <div className="form-section__col" id="main-content">
        <p className="caption">{caption}</p>
        {shouldLoadGoogleReCaptchaProvider ? (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}
          >
            <ConsentForm />
          </GoogleReCaptchaProvider>
        ) : (
          <ConsentForm />
        )}
      </div>
    </section>
  );
};

export default SubscribeForm;

export const SignUpType = graphql`
  fragment SubscribeFormType on SubscribeFormType {
    caption
  }
`;
