import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import SubscribeForm from 'components/SubscribeForm';
import ThankYou from 'components/ThankYou';

const Subscribe = ({
  data: {
    subscribe: { urls, seo, heroBanner, form, thankYou },
  },
  pageContext: {
    breadcrumb: { crumbs },
    lang,
  },
}: SubscribeTypes.SubscribeProps) => {
  const [showForm, setShowForm] = useState(true);
  const { title, keywords, description } = seo;
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  const signHandler = (isSubscribed) => setShowForm(!isSubscribed);

  return (
    <Layout {...{ crumbsSettings }} headerTransparent className="subscribe-page">
      <Seo {...{ lang, urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...heroBanner} className={!showForm ? 'd-none' : undefined} />
      {showForm ? (
        <SubscribeForm {...form} signHandler={signHandler} />
      ) : (
        <ThankYou {...thankYou} />
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $id: String) {
    subscribe(lang: { eq: $lang }, id: { eq: $id }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      heroBanner {
        ...BannerType
      }
      form {
        ...SubscribeFormType
      }
      thankYou {
        ...ThankYouType
      }
    }
  }
`;

export default Subscribe;
