import React, { FC } from 'react';
import { graphql } from 'gatsby';

import CustomButton from 'common/CustomButton';

import './ThankYou.scss';

const ThankYou: FC<SubscribeTypes.ThankYouProps> = ({ title, description, customButton }) => {
  const { buttonLabel, backgroundColor, ariaLabel, buttonLink } = customButton || {};

  return (
    <section className="thank-you">
      <div className="thank-you__content" id="main-content">
        <h1>{title}</h1>
        <p>{description}</p>
        <CustomButton {...{ buttonLabel, backgroundColor, ariaLabel, buttonLink }} />
      </div>
    </section>
  );
};

export default ThankYou;

export const ThankYouType = graphql`
  fragment ThankYouType on ThankYouType {
    title
    description
    customButton {
      ...CustomButtonType
    }
  }
`;
